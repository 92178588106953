import * as React from "react";
import {NotificationContainer} from 'react-notifications';

import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import PageHelmet from "components/common/PageHelmet";

interface Props {
    title: string;
}

export default class Layout extends React.Component<Props, {}> {

    render() {
        return (
            <>
                <PageHelmet title={this.props.title} />
                
                <Header headerPosition="header--static logoresize" />

                <NotificationContainer/>

                {this.props.children}            

                {/* Footer */}
                <Footer />
            </>
        );
    }
}