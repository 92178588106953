import * as React from "react";
import {FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import {routeDefinition} from "utils";
import {RouteType} from "enums/routeType";

const SocialLinks = [
    {Social: <FaFacebookF />, link: "https://www.facebook.com/Vinorocwineimports"},
    {Social: <FaLinkedinIn />, link: "https://www.linkedin.com/"},
    {Social: <FaInstagram />, link: "https://www.instagram.com/vinorocwineimports"},
];

interface Props {}

export default class Footer extends React.Component<Props> {
    static defaultProps: Props = {};

    render() {
        const year = new Date().getFullYear();
        const copyright = `Copyright © ${year} Vinoroc. All Rights Reserved`;

        return (
            <footer>
                <div className="footer-style-2 ptb--30 bg_image">
                    <div className="wrapper plr--50 plr_sm--20">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="inner">
                                    <div className="logo text-center text-sm-left mb_sm--20">
                                        <a href={routeDefinition(RouteType.home)}>
                                            <img
                                                src="/assets/images/logo/vinoroc-logo-transparent.png"
                                                alt="Logo images"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="inner text-center">
                                    <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                        {SocialLinks.map((val, i) => (
                                            <li key={i}>
                                                <a href={`${val.link}`} target="_blank">
                                                    {val.Social}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                                <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                                    <div className="text">
                                        <p>{copyright}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
