import React, {useState, useEffect} from "react";
import {RouteComponentProps} from "react-router-dom";
import {Tabs, Tab} from "react-bootstrap";

import SpinnerComponent from "components/common/Spinner";
import WineService from "services/wine.service";

import DescriptionTab from "./sections/description-tab";
import ManufacturerTab from "./sections/manufacturer-tab";
import TastingsNotesTab from "./sections/tasting-notes";
import {IWineModel} from "models/wineModel";
import Layout from "components/common/layout";

interface Props {
    manufacturer_id: string;
    wine_id: string;
}

interface WineDetails extends RouteComponentProps<Props> {}

const WineDetails: React.FC<WineDetails> = (props) => {
    const [wine, setWine] = useState(null as IWineModel);
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState("manufacturer");

    useEffect(() => {
        const {manufacturer_id, wine_id} = props.match.params;
        
        setLoading(true);
        
        WineService.getWine(manufacturer_id, wine_id)
            .then(result => {
                setWine(result);
                setLoading(false);
            }).catch(error =>{
                setLoading(false);
        });
        
    }, []);

    return (
        <Layout title="Wine Details">
            
            <React.Fragment>
                <SpinnerComponent is_loading={loading} />

                {!loading && wine && (
                    <React.Fragment>
                        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-3">
                                        <img
                                            className="w-100"
                                            src={wine.logo.url}
                                            alt={wine.logo.name}
                                        />
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-8 wine-item">
                                        <h3 className="manufacturer-name">{wine.manufacturer.name}</h3>
                                        <h2 className="title">{wine.name}</h2>
                                        <p>{wine.shortDescription}</p>
                                        <br />
                                        
                                        {/* TABS SECTION */}
                                        <Tabs
                                            id="wine-description"
                                            activeKey={key}
                                            onSelect={(k) => setKey(k)}
                                        >
                                            <Tab eventKey="description" title="Description">
                                                <DescriptionTab wine={wine} />
                                            </Tab>
                                            <Tab eventKey="tasting_notes" title="Tasting Notes">
                                                <TastingsNotesTab wine={wine} />
                                            </Tab>
                                            <Tab eventKey="manufacturer" title="Manufacturer">
                                                <ManufacturerTab wine={wine} />
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>

        </Layout>
    );
};

export default WineDetails;
