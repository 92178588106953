import axios from "axios";
import Notification from "components/notification";
import {NotificationType} from "enums";

const notification = new Notification();

const http = (headers = []) => {
    const instance = axios.create(buildRequestConfig(headers));

    const isClientError = [
        (response) => {
            return response;
        },
        (error) => {
            notification.create(NotificationType.error, "Something went wrong!");
            
            return Promise.reject(error);
        }
    ];

    instance.interceptors.response.use(...isClientError);

    return instance;
};

const buildRequestConfig = (headers) => {
    // headers
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
        }
    };

    if (headers) {
        headers.forEach((element) => {
            for (let [key, value] of Object.entries(element)) {
                console.log(`${key}: ${value}`);
                config.headers[key] = value;
            }
        });
    }

    return config;
};

const createUrl = (path) => {
    return `${process.env.PUBLIC_URL}/api/${path}`;
};

export { http, createUrl };