import * as React from "react";
import {Table} from "react-bootstrap";
import {GiErlenmeyer, GiGrapes, GoLocation} from "react-icons/all";
import {IWineModel} from "models/wineModel";

interface Props {
    wine: IWineModel
}

const DescriptionTab : React.FC<Props> = (props) => {
    
    return(
        <div className="tab-container">
            <Table hover>
                <tbody>
                <tr>
                    <td>
                        <GiGrapes
                            size="2em"
                            color="#669"
                        />
                    </td>
                    <td>Grapes</td>
                    <td>{props.wine.grapeType.map(x=> x.name).join(", ")}</td>
                </tr>
                <tr>
                    <td>
                        <GoLocation
                            size="2em"
                            color="#669"
                        />
                    </td>
                    <td>Region</td>
                    <td>{props.wine.manufacturer.region.country.name} / {props.wine.manufacturer.region.name}</td>
                </tr>
                <tr>
                    <td>
                        <GiErlenmeyer
                            size="2em"
                            color="#669"
                        />
                    </td>
                    <td>Allergens</td>
                    <td>{props.wine.allergens.join(", ")}</td>
                </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default DescriptionTab;