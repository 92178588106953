import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";

const Wrapper = styled.main`
    width: 100%;
    height: 100%;
`;

const GoogleMap = ({children, ...props}) => (
    <Wrapper>
        <GoogleMapReact
            bootstrapURLKeys={{
                key: "AIzaSyAuyqpCV8zsdyiymp0GdEk2txNuXQ333wI"
            }}
            yesIWantToUseGoogleMapApiInternals
            {...props}
        >
            {children}
        </GoogleMapReact>
    </Wrapper>
);

GoogleMap.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};

GoogleMap.defaultProps = {
    children: null
};

export default GoogleMap;
