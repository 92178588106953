import React, {Component} from "react";
import {Link} from "react-router-dom";
import {FiX, FiMenu} from "react-icons/fi";

import {routeDefinition} from "utils";
import {RouteType} from "enums/routeType";

interface Props {
    headerPosition: string;
}

class Header extends Component<Props, {}> {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        window.addEventListener("load", function () {
        });
    }
    menuTrigger() {
        document.querySelector(".header-wrapper").classList.toggle("menu-open");
    }
    CLoseMenuTrigger() {
        document.querySelector(".header-wrapper").classList.remove("menu-open");
    }
    render() {
        // const elements = document.querySelectorAll(".has-droupdown > a");
        // for (const i in elements) {
        //     if (elements.hasOwnProperty(i)) {
        //         elements[i]
        //             .onclick = function () {
        //             this.parentElement.querySelector(".submenu").classList.toggle("active");
        //             this.classList.toggle("open");
        //         };
        //     }
        // }

        return (
            <header className="header-area formobile-menu header--static logoresize">
                <div className="header-wrapper" id="header-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="header-left">
                                    <div className="logo">
                                        <a href={routeDefinition(RouteType.home)}>
                                            <img
                                                src="/assets/images/logo/vinoroc-logo.jpg"
                                                alt="Vinoroc"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8 col-6">
                                <div className="header-right justify-content-end">
                                    <nav className="mainmenunav d-lg-block">
                                        <ul className="mainmenu">
                                            <li>
                                                <Link to={routeDefinition(RouteType.wines)}>Our Wines</Link>
                                            </li>
                                            <li>
                                                <Link to={routeDefinition(RouteType.about)}>About</Link>
                                            </li>
                                            <li>
                                                <Link to={routeDefinition(RouteType.blogs)}>Blog</Link>
                                            </li>
                                            <li>
                                                <Link to={routeDefinition(RouteType.contact)}>Contact</Link>
                                            </li>
                                        </ul>
                                    </nav>

                                    {/* Start Humberger Menu  */}
                                    <div className="humberger-menu d-block d-lg-none pl--20">
                                        <span
                                            onClick={this.menuTrigger}
                                            className="menutrigger text-white"
                                        >
                                            <FiMenu />
                                        </span>
                                    </div>
                                    {/* End Humberger Menu  */}
                                    <div className="close-menu d-block d-lg-none">
                                        <span
                                            onClick={this.CLoseMenuTrigger}
                                            className="closeTrigger"
                                        >
                                            <FiX />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
export default Header;
