import * as api from "services/api";
import IContactUsModel from "models/contactUsModel";


const postContactUs = (model: IContactUsModel): Promise<void> => {
    return new Promise((resolve, reject) => {
        resolve(
            api.http().post(api.createUrl("contact"), model)
        )
    });
}
const ContactService = {
    postContactUs,
};

export default ContactService;
