import * as React from "react";
import {Spinner} from "react-bootstrap";

interface Props {
    is_loading: boolean;
}

export default class SpinnerComponent extends React.Component<Props, {}> {
    render() {
        const {is_loading} = this.props;
        return (
            <div className="text-center">
                {is_loading ? <Spinner animation="border" role="status" /> : null}
            </div>
        );
    }
}
