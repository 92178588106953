import React from 'react';
import {NotificationManager} from 'react-notifications';
import {NotificationType} from "enums";

class Notification {

    create = (type: NotificationType, message: string, callback: () => any = undefined) => {

        switch (type) {
            case NotificationType.info:
                NotificationManager.info(message);
                break;
            case NotificationType.success:
                NotificationManager.success(message);
                break;
            case NotificationType.warning:
                NotificationManager.warning(message);
                break;
            case NotificationType.error:
                NotificationManager.error(message, 'Click me!', 5000, () => {
                    if (callback !== undefined) {
                        callback();
                    }
                });
                break;
        }
    };
}

export default Notification;