import * as React from "react";
import {FiHeadphones, FiMail, FiMapPin} from "react-icons/fi";

import ContactUsForm from "./form";
import Layout from "components/common/layout";
import GoogleMap from "components/map/GoogleMap";
import Marker from "components/map/Marker";

interface Coords {
    lat: number;
    lng: number;
}

interface State {
    map: Coords;
    marker: Coords;
}

export default class Contact extends React.Component<{}, State> {

    constructor(props) {
        super(props);
        this.state = {
            map: {
                lat: 42.4711613,
                lng: -92.4574583
            } as Coords,
            marker: {
                lat: 42.4723965,
                lng: -92.4574583
            } as Coords
        };
    }

    render() {

        return (
            <Layout title="Contact Us">

                {/* Start Breadcrump Area */}
                <div
                    className="rn-page-title-area pt--120 pb--190 bg_image contact-us-banner"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            
                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail/>
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Address</h4>
                                        <p>
                                            <a href="mailto:vinorocwineimports@gmail.com">
                                            vinorocwineimports@gmail.com
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin/>
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Location</h4>
                                        <p>
                                            900 Technology Pkwy, Cedar Falls,<br/> IA 50613, United States
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}
                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactUsForm/>
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                <div className="rn-contact-map-area position-relative">
                    <div style={{height: "650px", width: "100%"}}>
                        <GoogleMap
                            defaultZoom={13}
                            defaultCenter={{
                                lat: this.state.map.lat,
                                lng: this.state.map.lng
                            }}
                        >
                            <Marker
                                key={1}
                                text="Vinoroc"
                                lat={this.state.marker.lat}
                                lng={this.state.marker.lng}
                            />
                        </GoogleMap>
                    </div>
                </div>
                {/* End Contact Map  */}
                
            </Layout>
        );
    }
}
