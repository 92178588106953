import * as React from "react";
import BlogService from "services/blog.service";
import {routeBuilder} from "utils";

import SpinnerComponent from "components/common/Spinner";
import IBlogModel from "models/blogModel";
import Pagination from "components/common/Pagination";
import {RouteType} from "enums";
import IRouteParamsModel from "models/routeParamsModel";
import Layout from "components/common/layout";

interface State {
    blog_items: Array<IBlogModel>;
    is_loading: boolean;
    total_count: number;
    current_page: number;
}

const PAGE_SIZE = 6;

export default class Blog extends React.Component<{}, State> {
    constructor(props) {
        super(props);
        this.state = {
            blog_items: [],
            is_loading: false,
            total_count: 0,
            current_page: 1
        }
    }

    componentDidMount() {
        this.getBlogs();
    }

    getBlogs() {
        this.setState({is_loading: true}, () => {

            const {current_page} = this.state;

            BlogService.getBlogs(current_page, PAGE_SIZE).then(response => {
                this.setState({
                    blog_items: response.result,
                    total_count: response.totalCount,
                    is_loading: false
                });
            }).catch(error => {
                this.setState({
                    is_loading: false
                });
            })
        });
    }

    render() {
        const {blog_items, is_loading, total_count, current_page} = this.state;

        return (
            <Layout title="Blogs">
                
                <SpinnerComponent is_loading={is_loading}/>

                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            {!is_loading && blog_items.map((item, index) => {
                                return this._renderBlogItem(item, index);
                            })}
                        </div>
                        <Pagination
                            total_items={total_count}
                            size={PAGE_SIZE}
                            current_page={current_page}
                            onPageChange={(page) => this.setState({current_page: page}, () => this.getBlogs())}
                            onNextPage={() => this.setState({current_page: current_page + 1}, () => this.getBlogs())}
                            onPreviousPage={() => this.setState({current_page: current_page - 1}, () => this.getBlogs())}
                        />
                    </div>
                </div>
                {/* End Blog Area */}

               
            </Layout>
        );
    }

    _renderBlogItem = (item: IBlogModel, index: number) => {

        const params = {
            blog_id: item.id
        } as IRouteParamsModel;
        
        const blog_url = routeBuilder(RouteType.blog_details, params);

        return (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                <div className="blog blog-style--1">
                    <div className="thumbnail">
                        <a href={blog_url}>
                            <img
                                className="w-100"
                                src={item.banner.url}
                                alt={item.banner.name}
                            />
                        </a>
                    </div>
                    <div className="content">
                        <p className="blogtype">{item.category}</p>
                        <h4 className="title">
                            <a href={blog_url}>{item.title}</a>
                        </h4>
                        <div className="blog-btn">
                            <a className="rn-btn text-white" href={blog_url}>
                                Read More
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
