import * as api from "services/api";
import {IWineModel, IWineType, IGrapeType} from "models/wineModel";
import IPaginatedModel from "models/paginatedModel";

const getTypes = (): Promise<Array<IWineType>> => {
     return new Promise<Array<IWineType>>((resolve, reject) => {
         resolve(
             api.http().get<Array<IWineType>>(
                 api.createUrl(`wines/types`)
             ).then((response) => {
                 
                 return response.data;
             })
         )
     });
}

const getWines = (manufacturer_id: string, wine_type_id: string, page: number, size: number): Promise<IPaginatedModel<IWineModel>> => {

    return new Promise<IPaginatedModel<IWineModel>>((resolve, reject) => {
        resolve(
            api.http().get<IPaginatedModel<IWineModel>>(
                api.createUrl(`wines?PageNumber=${page}&PageSize=${size}&WineTypeId=${wine_type_id}&ManufacturerId=${manufacturer_id}`)
            ).then((response) => {
                
                return {
                    result: response.data.result,
                    totalCount: response.data.totalCount
                } as IPaginatedModel<IWineModel>;
            })
        )
    });
}

const getWine = (manufacturer_id: string, wine_id: string): Promise<IWineModel> => {
    return new Promise<IWineModel>((resolve, reject) => {
        resolve(
            api.http().get<IWineModel>(
                api.createUrl(`wines/${manufacturer_id}/${wine_id}`)
            ).then((response) => {
                return response.data;
            })
        )
    })
}

const WineService = {
    getTypes,
    getWines,
    getWine
};

export default WineService;
