enum RouteType {
    home,
    wines,
    wine_details,
    about,
    blogs,
    blog_details,
    contact,
}

export {RouteType}  