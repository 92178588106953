import * as React from "react";
import {IWineModel} from "models/wineModel";

interface Props {
    wine: IWineModel

}

const TastingsNotesTab: React.FC<Props> = (props) => {

    return (
        <div className="tab-container">
            <p>
                {props.wine.tastingNotes}
            </p>
        </div>
    )
}

export default TastingsNotesTab;