import React, {useState, useEffect} from "react";

import RegionService from "services/region.service";
import {FilterOptions} from "../models";

const DEFAULT_OPTIONS = {name: "Select Region", value: ""};

interface Props {
    onChange: (text: string) => void;
}

const RegionsSelector: React.FC<Props> = (props) => {
    
    const [region_options, setRegionOptions] = useState<Array<FilterOptions>>([DEFAULT_OPTIONS]);
    const [region, setRegion] = useState(DEFAULT_OPTIONS.value);

    useEffect(() => {
        const options = [DEFAULT_OPTIONS] as Array<FilterOptions>;

        RegionService.getAll(1)
            .then((result) => {
            result.result.map((region) => {
                options.push({name: region.name, value: region.id});
            });

            setRegionOptions(options);
        })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    
    const onChange = (event) => {
        const value = event.target.value;
        setRegion(value);
        props.onChange(value);
    };

    return (
        <>
            <label>Region</label>
            <select className="text-center rounded" value={region} onChange={onChange}>
                {region_options.map((region) => {
                    return (
                        <option key={region.value} value={region.value}>
                            {region.name}
                        </option>
                    );
                })}
            </select>
        </>
    );
};

export default RegionsSelector;
