import * as React from "react";
import {useEffect, useState} from "react";

import {FilterOptions} from "../models";
import ManufacturerService from "services/manufacturer.service";

const DEFAULT_OPTIONS = {name: "Select Manufacturer", value: ""};

interface Props {
    region_id: string;
    onChange: (text: string) => void;
}

const ManufacturersSelector : React.FC<Props> = (props) => {

    const [manufacturer_options, setManufacturerOptions] = useState<Array<FilterOptions>>([DEFAULT_OPTIONS]);
    const [manufacturer, setManufacturer] = useState(DEFAULT_OPTIONS.value);
    
    useEffect(() => {
        const options = [DEFAULT_OPTIONS] as Array<FilterOptions>;
        
        if(props.region_id === "") {
            setManufacturerOptions(options);
            return;
        }
        
        ManufacturerService.getAll(props.region_id,1)
            .then((result) => {
                result.result.map((region) => {
                    options.push({name: region.name, value: region.id});
                });

                setManufacturerOptions(options);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [props.region_id]);

    const onChange = (event) => {
        const value = event.target.value;
        setManufacturer(value);
        props.onChange(value);
    };
    
    return (
        <>
            <label>Manufacturer</label>
            <select className="text-center rounded" value={manufacturer} onChange={onChange}>
                {manufacturer_options.map((manufacturer) => {
                    return (
                        <option key={manufacturer.value} value={manufacturer.value}>
                            {manufacturer.name}
                        </option>
                    );
                })}
            </select>
        </>
    );
};

export default ManufacturersSelector;
