import * as React from "react";
import GoogleMap from "components/map/GoogleMap";
import Marker from "components/map/Marker";
import {IWineModel} from "models/wineModel";

interface Props {
    wine: IWineModel
}

const ManufacturerTab : React.FC<Props> = (props) => {

    return(
        <div className="tab-container">
            <div className="rn-contact-map-area position-relative">
                <div className="row mt-4 mb-4">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                        <img
                            className="w-100"
                            src={props.wine.manufacturer.logo.url}
                            alt={props.wine.manufacturer.logo.name}
                        />
                    </div>
                    <div className="col-md-4"></div>
                </div>
                <div className="mb-4">
                    <p>
                        {props.wine.manufacturer.description}
                    </p>
                </div>
                <div
                    style={{height: "650px", width: "100%"}}
                >
                    <GoogleMap
                        defaultZoom={7}
                        defaultCenter={{
                            lat: props.wine.manufacturer.region.country.latitude,
                            lng: props.wine.manufacturer.region.country.longitude
                        }}
                    >
                        <Marker
                            key={1}
                            text={props.wine.manufacturer.region.name}
                            lat={props.wine.manufacturer.region.latitude}
                            lng={props.wine.manufacturer.region.longitude}
                        />
                    </GoogleMap>
                </div>
            </div>
        </div>
    )
}

export default ManufacturerTab;