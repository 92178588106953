import * as api from "services/api";
import IPaginatedModel from "models/paginatedModel";
import IManufacturerModel from "models/manufacturerModel";

const getAll = (region_id: string, page: number): Promise<IPaginatedModel<IManufacturerModel>> => {
    return new Promise<IPaginatedModel<IManufacturerModel>>((resolve, reject) => {
       resolve(
           api.http().get<IPaginatedModel<IManufacturerModel>>(
               api.createUrl(`regions/${region_id}/manufacturers?PageNumber=${page}&PageSize=50`)
           ).then((response) => {
               
               return {
                   result: response.data.result,
                   totalCount: response.data.totalCount
               } as IPaginatedModel<IManufacturerModel>;
           })
       ) 
    });
};

const ManufacturerService = {
    getAll
};

export default ManufacturerService;
