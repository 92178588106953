import * as React from "react";

import {isValidEmail} from "utils";
import SpinnerComponent from "components/common/Spinner";
import ContactService from "services/contact.service";
import IContactUsModel from "models/contactUsModel";
import Notification from "components/notification";
import {NotificationType} from "enums";

interface State {
    name: string;
    email: string;
    subject: string;
    message: string;
    sending: boolean;
}

export default class ContactUsForm extends React.Component<{}, State> {
    private notification: Notification;

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            subject: "",
            message: "",
            sending: false,
        };

        this.notification = new Notification();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({sending: true}, () => {

            const {name, email, subject, message} = this.state;

            const model = {
                name: name,
                email: email,
                subject: subject,
                message: message,
            } as IContactUsModel;

            ContactService.postContactUs(model).then(() => {
                
                this.notification.create(NotificationType.success, "Your message has been sent successfully!");
                
                this.setState({
                    sending: false, 
                    name: "",
                    email: "",
                    subject: "",
                    message: "",
                });
            }).catch(() => {
                this.setState({sending: false});
            })
        });


    }

    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact Us.</h2>
                                <p className="description">
                                We're thrilled to hear from you! 
                                Whether you're a wine connoisseur, a distributor seeking partnership, or an event planner 
                                interested in our products and services, we're here to assist you.
                                </p>
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={this.handleSubmit}>
                                    <label htmlFor="name">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={this.state.name}
                                            onChange={(e) => {
                                                this.setState({name: e.target.value});
                                            }}
                                            placeholder="Your Name *"
                                            required={true}
                                        />
                                    </label>

                                    <label htmlFor="email">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            value={this.state.email}
                                            onChange={(e) => {
                                                this.setState({email: e.target.value});
                                            }}
                                            placeholder="Your email *"
                                            style={{borderColor: isValidEmail(this.state.email) ? "" : "red"}}
                                            required={true}
                                        />
                                    </label>

                                    <label htmlFor="subject">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="subject"
                                            value={this.state.subject}
                                            onChange={(e) => {
                                                this.setState({subject: e.target.value});
                                            }}
                                            placeholder="Write a Subject * "
                                            required={true}
                                        />
                                    </label>
                                    <label htmlFor="message">
                                        <textarea
                                            id="message"
                                            name="message"
                                            value={this.state.message}
                                            onChange={(e) => {
                                                this.setState({message: e.target.value});
                                            }}
                                            placeholder="Your Message *"
                                            required={true}
                                        />
                                    </label>

                                    {!this.state.sending && (
                                        <button
                                            className="rn-button-style--2 btn-solid"
                                            type="submit"
                                            value="submit"
                                            name="submit"
                                            id="mc-embedded-subscribe"
                                            disabled={this.state.sending}
                                        >
                                            Submit
                                        </button>
                                    )}
                                    {this.state.sending && <SpinnerComponent is_loading={true}/>}
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/contact/lets-get-in-touch.jpg" alt="Get In Touch"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
