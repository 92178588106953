import React, {Component} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import 'react-notifications/lib/notifications.css';

import reportWebVitals from "./reportWebVitals";
import {routeDefinition} from "utils";
import {RouteType} from "enums/routeType";
import "./index.scss";

// pages
import HomePage from "pages/home";
import Wines from "pages/wines";
import WineDetails from "pages/wine-details";
import About from "pages/about-us";
import Contact from "pages/contact-us";
import Blog from "pages/blogs";
import BlogDetails from "pages/blog-details";
import Error from "pages/error";
import Error404 from "pages/404";

const base_url = process.env.PUBLIC_URL;

export default function Root() {
    return (
        <>
            <BrowserRouter basename={routeDefinition(RouteType.home)}>
                <Switch>
                    <Route exact path={`${base_url}${routeDefinition(RouteType.home)}`} component={HomePage} />
                    <Route exact path={`${base_url}${routeDefinition(RouteType.wines)}`} component={Wines} />
                    <Route
                        exact
                        path={`${base_url}${routeDefinition(RouteType.wine_details)}`}
                        component={WineDetails}
                    />
                    <Route exact path={`${base_url}${routeDefinition(RouteType.about)}`} component={About} />
                    <Route
                        exact
                        path={`${base_url}${routeDefinition(RouteType.contact)}`}
                        component={Contact}
                    />
                    <Route exact path={`${base_url}${routeDefinition(RouteType.blogs)}`} component={Blog} />
                    <Route
                        exact
                        path={`${base_url}${routeDefinition(RouteType.blog_details)}`}
                        component={BlogDetails}
                    />
                    <Route path={`${process.env.PUBLIC_URL}/error`} component={Error} />
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={Error404} />
                    <Route component={Error404} />
                </Switch>
            </BrowserRouter>
        </>
    );
}

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
