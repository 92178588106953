import {RouteType} from "enums/routeType";
import IRouteParamsModel from "models/routeParamsModel";

const routeBuilder = (type: RouteType, params: IRouteParamsModel): string => {

    const definition = routeDefinition(type);

    switch (type) {
        case RouteType.home:
            return definition;
        case RouteType.wines:
            return definition;
        case RouteType.wine_details:
            return definition.replace(":manufacturer_id", params.manufacturer_id).replace(":wine_id", params.wine_id);
        case RouteType.blogs:
            return definition;
        case RouteType.blog_details:
            return definition.replace(":blog_id", params.blog_id);
        case RouteType.contact:
            return definition;
        case RouteType.about:
            return definition;
        default:
            return "/";
    }
}

const routeDefinition = (type: RouteType): string => {

    switch (type) {
        case RouteType.home:
            return "/";
        case RouteType.wines:
            return "/wines";
        case RouteType.wine_details:
            return "/wines/:manufacturer_id/:wine_id";
        case RouteType.blogs:
            return "/blogs";
        case RouteType.blog_details:
            return "/blogs/:blog_id";
        case RouteType.contact:
            return "/contact-us";
        case RouteType.about:
            return "/about-us";
        default:
            return "/";
    }
}

export {routeBuilder, routeDefinition};