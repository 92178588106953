import React from "react";
import PropTypes from "prop-types";

const LinkRenderer = (props) => (
    <React.Fragment>
        <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
        </a>
    </React.Fragment>
);

export default LinkRenderer;
