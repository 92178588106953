import * as api from "services/api";
import IPaginatedModel from "models/paginatedModel";
import IBlogModel from "models/blogModel";

const getBlogs = (page: number, size: number): Promise<IPaginatedModel<IBlogModel>> => {

    return new Promise<IPaginatedModel<IBlogModel>>((resolve, reject) => {
        resolve(
            api.http().get<IPaginatedModel<IBlogModel>>(
                api.createUrl(`blogs?PageNumber=${page}&PageSize=${size}`)
            ).then((response) => {

                return {
                    result: response.data.result,
                    totalCount: response.data.totalCount
                } as IPaginatedModel<IBlogModel>;
            })
        )
    });
}

const getBlog = (blog_id: string): Promise<IBlogModel> => {
    return new Promise<IBlogModel>((resolve, reject) => {
        resolve(
            api.http().get<IBlogModel>(
                api.createUrl(`blogs/${blog_id}`)
            ).then((response) => {
                return response.data;
            })
        )
    })
}

const BlogService = {
    getBlogs,
    getBlog
};

export default BlogService;
