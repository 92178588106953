import React, {useState, useEffect} from "react";
import {RouteComponentProps} from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import moment from "moment/moment";
import {FiClock, FiUser} from "react-icons/fi";
import {IoMdPaper} from "react-icons/io";

import SpinnerComponent from "components/common/Spinner";
import BlogService from "services/blog.service";
import IBlogModel from "models/blogModel";
import Layout from "components/common/layout";
import LinkRenderer from "components/markdown/LinkRenderer";

interface Props {
    blog_id: string;
}

interface BlogDetails extends RouteComponentProps<Props> {}

const BlogDetails: React.FC<BlogDetails> = (props) => {
    const [blog, setBlog] = useState(null as IBlogModel);
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState("manufacturer");

    useEffect(() => {
        const {blog_id} = props.match.params;
        console.log(props)
        setLoading(true);

        BlogService.getBlog(blog_id)
            .then(result => {
                setBlog(result);
                setLoading(false);
            }).catch(error =>{
            setLoading(false);
        });

    }, []);

    return (
        <Layout title="Blog Details">

            <React.Fragment>
                <SpinnerComponent is_loading={loading} />

                {!loading && blog && (
                    <>
                        {/* Start Breadcrump Area */}
                        <div
                            className="rn-page-title-area pt--120 pb--190 bg_image"
                            style={{backgroundImage: `url(${blog.banner.url})`}}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="blog-single-page-title text-center pt--100">
                                            <h2 className="title theme-gradient">
                                                
                                            </h2>
                                            <ul className="blog-meta d-flex justify-content-center align-items-center">
                                                <li>
                                                    <FiClock />
                                                    {moment(blog.createdDate).format("d MMM yyyy")}
                                                </li>
                                                <li>
                                                    <FiUser />
                                                    {blog.author}
                                                </li>
                                                <li>
                                                    <IoMdPaper />
                                                    {blog.category}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Breadcrump Area */}

                        {/* Start Blog Details */}
                        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner-wrapper">
                                            <div className="inner">
                                                {<ReactMarkdown components={{ a: LinkRenderer }}>{blog.description}</ReactMarkdown>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Blog Details */}
                    </>
                )}
                
            </React.Fragment>
            
        </Layout>
    );
};

export default BlogDetails;
