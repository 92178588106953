import * as React from "react";

import {FiltersState} from "../models"

import RegionsSelector from "./Regions";
import ManufacturersSelector from "./Manufacturers";
import WineTypeSelector from "./WineType";

interface State {
    filters: FiltersState;
}

interface Props {
    onChange: (filters: FiltersState) => void;
}

enum FilterType {
    REGION = "REGION",
    MANUFACTURER = "MANUFACTURER",
    WINE_TYPE = "WINE_TYPE"
}

export default class WineFilters extends React.Component<Props, State> {
    constructor(props) {
        super(props);
    }

    state: State = {
        filters: {
            selected_region: "",
            selected_manufacturer: "",
            selected_wine_type: ""
        }
    };

    saveState = (type: FilterType, value: string) => {

        let updated_filters: FiltersState;

        const {filters} = this.state;

        switch (type) {
            case FilterType.REGION:
                updated_filters = {
                    selected_region: value, selected_manufacturer: "", selected_wine_type: ""
                };
                break;
            case FilterType.MANUFACTURER:
                updated_filters = {
                    selected_region: filters.selected_region, selected_manufacturer: value, selected_wine_type: ""
                };
                break;
            case FilterType.WINE_TYPE:
                updated_filters = {
                    selected_region: filters.selected_region, selected_manufacturer: filters.selected_manufacturer, selected_wine_type: value
                };
                break;
            default:
                updated_filters = {
                    selected_region: "", selected_manufacturer: "", selected_wine_type: ""
                };
                break;
        }

        this.setState({filters: updated_filters}, () => {
            this.props.onChange(this.state.filters);
        });
    }

    render() {
        const {filters} = this.state;

        return (
            <div className="container mt--20">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <RegionsSelector onChange={(region) => this.saveState(FilterType.REGION, region)}/>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <ManufacturersSelector
                            region_id={filters.selected_region}
                            onChange={(manufacturer) => this.saveState(FilterType.MANUFACTURER, manufacturer)}/>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <WineTypeSelector onChange={(type) => this.saveState(FilterType.WINE_TYPE, type)}/>
                    </div>
                </div>
            </div>
        );
    }
}
