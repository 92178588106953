import React, {Component} from "react";
import moment from "moment/moment";
import IWineModel from "models/wineModel";
import IRouteParamsModel from "models/routeParamsModel";
import {routeBuilder} from "utils";
import {RouteType} from "enums";
import SpinnerComponent from "components/common/Spinner";
import WineService from "services/wine.service";

interface Props {}

interface State {
    wines: Array<IWineModel>;
    is_loading: boolean;
}

export default class TeaserWines extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            wines: [],
            is_loading: false
        };

        this.getWines = this.getWines.bind(this);
    }

    componentDidMount() {
        this.getWines();
    }

    getWines() {
        const wines_to_show = [
            {
                id: "45IRcjJSo8rAHdzc6PDutE",
                name: "Cabernet Sauvignon – Feteasca Neagra",
                index: 0
            },
            {
                id: "66m6F12btnYo1bcRWUNS0C",
                name: "Rara Neagra",
                index: 1
            },
            {
                id: "4ifXxT5aYie0g4upszO16j",
                name: "Cabernet Sauvignon - Merlot",
                index: 2
            }
        ];

        const fautor_manufacturer_id = "fBgNqZJXgYN2spl4MWDwg";

        this.setState({is_loading: true}, () => {
            const promiseCollection = [];

            wines_to_show.forEach((wine) => {
                const apiResult = WineService.getWine(fautor_manufacturer_id, wine.id).then(
                    (result) => {
                        return result;
                    }
                );

                promiseCollection.push(apiResult);
            });

            Promise.all(promiseCollection)
                .then((result) => {

                const wines = result.map((wine, index) => {

                    const params = {
                        manufacturer_id: wine.manufacturer.id,
                        wine_id: wine.id
                    } as IRouteParamsModel;
                    const wine_url = routeBuilder(RouteType.wine_details, params);

                    return {
                        ...wine,
                        index: wines_to_show[index].index,
                        url: wine_url
                    };
                });

                wines.sort((a, b) => {
                    return a.index - b.index;
                });

                this.setState({wines: wines, is_loading: false});
            }).
            catch((error) => {
                this.setState({is_loading: false});
            });
        });
    }

    render() {
        const {wines, is_loading} = this.state;

        if (is_loading) return <SpinnerComponent is_loading={is_loading} />;

        console.log("wines", wines);

        return (
            <div className="service-area ptb--30 bg_color--1">
                <div className="container">
                    <div className="row service-one-wrapper">
                    {wines.map((item, i) => (
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                <a className="text-center" href={item.url}>
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            <img src={item.logo.url} />
                                        </div>

                                        <div className="content">
                                            <h3 className="title">{item.name}</h3>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
