import * as React from "react";
import {Link} from "react-router-dom";
import {FaAngleRight, FaAngleLeft} from "react-icons/fa";

interface Props {
    total_items: number;
    size: number; // items per page
    current_page: number;
    onPreviousPage: () => void;
    onNextPage: () => void;
    onPageChange: (page: number) => void;
}

interface State {
}

export default class Pagination extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const {total_items, size, onNextPage, onPreviousPage, onPageChange, current_page} = this.props;

        const page_count = total_items / size;
        const pages = [];

        if (page_count <= 1) return null;

        for (let i = 0; i <= page_count; i++) {
            pages.push({
                href: "#",
                number: i + 1,
                is_active: current_page === i + 1
            });
        }

        return (
            <div className="rn-pagination text-center">
                <ul className="page-list">
                    {current_page > 1 && (
                        <li onClick={onPreviousPage}>
                            <Link to="#">
                                <FaAngleLeft />
                            </Link>
                        </li>
                    )}

                    {pages.map((page, i) => {
                        return (
                            <li key={i} className={page.is_active ? "active" : ""} 
                                onClick={()=>{
                                    if(page.is_active) return;
                                    onPageChange(page.number);
                                }}>
                                <Link to={page.href}>{page.number}</Link>
                            </li>
                        );
                    })}

                    {current_page < page_count && (
                        <li onClick={onNextPage}>
                            <Link to="#">
                                <FaAngleRight />
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}
