import * as React from "react";
import {FaFacebookF, FaLinkedinIn} from "react-icons/fa";

import Layout from "components/common/layout";

interface State {
    team_list: Array<TeamItem>;
}

interface TeamItem {
    name: string;
    role: string;
    img_src: string;
    fb_link: string;
    linkedin_link: string;
}

export default class About extends React.Component<{}, State> {
    constructor(props) {
        super(props);
        this.state = {
            team_list: []
        };
    }
    componentDidMount() {
        const team = [
            {
                name: "Sergiu Barbu",
                role: "Owner",
                img_src: "/assets/images/team/Photo-Sergiu_5.jpeg",
                fb_link: "https://www.facebook.com/",
                linkedin_link: "https://www.linkedin.com/in/sergiu-barbu-33a9b08/"
            },
            {
                name: "Mihai Gliga",
                role: "Developer",
                img_src: "/assets/images/team/Photo-Mihai.jpg",
                fb_link: "https://www.facebook.com/",
                linkedin_link: "https://www.linkedin.com/in/mihai-gliga-758563b9"
            },
            {
                name: "Daniela Gliga",
                role: "Social Media Manager",
                img_src: "/assets/images/team/Photo-Daniela.jpg",
                fb_link: "https://www.facebook.com/",
                linkedin_link: "https://www.linkedin.com"
            }
        ];

        this.setState({team_list: team});
    }

    render() {
        const {team_list} = this.state;

        return (
            <Layout title="About Us">
                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img
                                            className="w-100"
                                            src="/assets/images/about/Fautor_Vineyard_4_v2.jpg"
                                            alt="About Images"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">About</h2>
                                            <p className="description">
                                                Vinoroc, an Iowa-based import and distribution
                                                company, is dedicated to delivering exceptional and
                                                distinctive wines to the American consumer. Our
                                                passion lies in introducing our Moldovan wines, each
                                                bottle steeped in the rich legacy of generations of
                                                winemaking tradition. We are excited to share the
                                                unique flavors and stories that Moldova has to
                                                offer. Welcome to Vinoroc, where quality and
                                                tradition meet in every bottle.
                                            </p>
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">
                                                        From our Owner & President
                                                    </h3>
                                                    <p>
                                                        An Eastern European nation nestled between
                                                        Romania and Ukraine, Moldova is renowned for
                                                        its rich history and winemaking traditions,
                                                        stands as a testament to the enduring legacy
                                                        of viticulture. Despite its modest size,
                                                        Moldova proudly claims the world's highest
                                                        concentration of vineyards and an extensive
                                                        network of underground cellars, preserving
                                                        the essence of its wine heritage.
                                                    </p>
                                                    <p>
                                                        Join us on a captivating journey of
                                                        discovery through Moldova’s winemaking
                                                        tradition and savor its distinctive wines.
                                                        In Moldova, we say, "Noroc!" before raising
                                                        a glass of wine, a heartfelt toast
                                                        symbolizing not only good fortune but also
                                                        the celebration of shared moments and
                                                        cherished traditions. We look forward to
                                                        raising our glasses with you and toasting to
                                                        the enduring legacy of Moldovan winemaking.
                                                        Noroc! Cheers!
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title">Our Team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {team_list.map((team_item, index) => {
                                return (
                                    <div key={index} className="col-lg-4 col-md-4 col-sm-4 col-12">
                                        <div className="team">
                                            <div className="thumbnail">
                                                <img
                                                    className="w-100"
                                                    src={team_item.img_src}
                                                    alt="Blog Images"
                                                />
                                            </div>
                                            <div className="content">
                                                <h4 className="title">{team_item.name}</h4>
                                                <p className="designation">{team_item.role}</p>
                                            </div>
                                            <ul className="social-icon">
                                                <li>
                                                    <a href={team_item.linkedin_link} target="_blank">
                                                        <FaLinkedinIn />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}
            </Layout>
        );
    }
}
