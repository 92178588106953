import React, {Component} from "react";
import moment from "moment/moment";
import IBlogModel from "models/blogModel";
import IRouteParamsModel from "models/routeParamsModel";
import {routeBuilder} from "utils";
import {RouteType} from "enums";
import BlogService from "services/blog.service";
import SpinnerComponent from "components/common/Spinner";

interface Props {}

interface State {
    blog_items: Array<IBlogModel>;
    is_loading: boolean;
}

export default class LatestBlogs extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            blog_items: [],
            is_loading: false
        };
        
        this.getBlogs = this.getBlogs.bind(this);
    }
    
    componentDidMount() {
        this.getBlogs();
    }

    getBlogs() {
        this.setState({is_loading: true}, () => {


            BlogService.getBlogs(1, 3).then(response => {
                this.setState({
                    blog_items: response.result,
                    is_loading: false
                });
            }).catch(error => {
                this.setState({
                    is_loading: false
                });
            })
        });
    }

    render() {
        
        const {blog_items, is_loading} = this.state;
        
        if(is_loading) return <SpinnerComponent is_loading={is_loading}/>

        return (
            <div className="rn-blog-area pt--120 pb--80 bg_color--1">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-12">
                            <div className="section-title service-style--3 text-center">
                                <h2 className="title">Latest News</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--60">
                        {this.state.blog_items.map((blog, index) => {
                            const params = {
                                blog_id: blog.id
                            } as IRouteParamsModel;

                            const blog_url = routeBuilder(RouteType.blog_details, params);
                            return(
                                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href={blog_url}>
                                                <img className="w-100" src={blog.banner.url} alt={blog.banner.name} />
                                            </a>
                                        </div>
                                        <div className="content">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <p className="blogtype">{blog.category}</p>
                                                </div>
                                                <div className="col-md-4"></div>
                                                <div className="col-md-4">
                                                    <p className="blogtype">
                                                        {moment(blog.createdDate).format("d MMM yyyy")}
                                                    </p>
                                                </div>
                                            </div>

                                            <h4 className="title">
                                                <a href={blog_url}>{blog.title}</a>
                                            </h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={blog_url}>
                                                    Read More
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}
