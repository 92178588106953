import * as React from "react";
import {useEffect, useState} from "react";

import {FilterOptions} from "../models";
import WineService from "services/wine.service";

const DEFAULT_OPTIONS = {name: "Select Type", value: ""};

interface Props {
    onChange: (text: string) => void;
}

const WineTypeSelector : React.FC<Props> = (props) =>  {
    
    const [wine_type_options, setWineTypeOptions] = useState<Array<FilterOptions>>([DEFAULT_OPTIONS]);
    const [wine_type, setWineType] = useState(DEFAULT_OPTIONS.value);

    useEffect(() => {
        const options = [DEFAULT_OPTIONS] as Array<FilterOptions>;
        
        WineService.getTypes()
            .then((result) => {
                console.log(result);
                result.map((type) => {
                    options.push({name: type.name, value: type.id});
                });
                console.log(options);
                setWineTypeOptions(options);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const onChange = (event) => {
        const value = event.target.value;
        setWineType(value);
        props.onChange(value);
    };
    
    return (
        <>
            <label>Type</label>
            <select className="text-center rounded" value={wine_type} onChange={onChange}>
                {wine_type_options.map((type) => {
                    return (
                        <option key={type.value} value={type.value}>
                            {type.name}
                        </option>
                    );
                })}
            </select>
        </>
    );
};

export default WineTypeSelector;
