import * as React from "react";

import Pagination from "components/common/Pagination";
import SpinnerComponent from "components/common/Spinner";
import {IWineModel} from "models/wineModel";
import IRouteParamsModel from "models/routeParamsModel";
import {RouteType} from "enums/routeType";
import WineService from "services/wine.service";
import {routeBuilder} from "utils";
import WineFilters from "./filters";
import {FiltersState} from "./models"
import Layout from "components/common/layout";

interface State {
    wine_list: Array<IWineModel>;
    filters: FiltersState;
    total_count: number;
    current_page: number;
    is_loading: boolean;
}

const PAGE_SIZE = 6;

export default class Wines extends React.Component<{}, State> {
    constructor(props) {
        super(props);

        this._renderWineList = this._renderWineList.bind(this);
    }

    state: State = {
        wine_list: [],
        filters: {
            selected_region: "",
            selected_manufacturer: "",
            selected_wine_type: ""
        },
        total_count: 0,
        current_page: 1,
        is_loading: false
    };
    
    componentDidMount() {
        this.getWines();
    }

    onFilterChange = (filters: FiltersState) => {
        this.setState({filters, total_count: 0, current_page: 1, wine_list: []}, () => {
            this.getWines();
        });

    }

    getWines = () => {
        const {selected_region, selected_manufacturer, selected_wine_type} = this.state.filters;
        const {current_page} = this.state;

        this.setState({is_loading: true});

        WineService.getWines(selected_manufacturer, selected_wine_type, current_page, PAGE_SIZE)
            .then((response) => {
                this.setState({wine_list: response.result, total_count: response.totalCount, is_loading: false});
            })
            .catch((error) => {
                this.setState({is_loading: false});
                console.log(error);
                //todo: show error message
            });
    }

    render() {
        const {is_loading} = this.state;

        return (
            <Layout title="Wines">
                
                {/* Top banner */}
                <div
                    className="rn-page-title-area pt--250 pb--190 bg_image wines-banner"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100"></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* wine filters */}
                <WineFilters onChange={(filters) => this.onFilterChange(filters)}/>

                {/* wine list */}
                <SpinnerComponent is_loading={is_loading}/>
                <this._renderWineList/>

            </Layout>
        );
    }

    _renderWineList() {
        const {wine_list, total_count, current_page} = this.state;

        return (
            <React.Fragment>
                <div className="creative-portfolio-wrapper ptb--40 bg_color--1">
                    <div className="container plr--10">
                        <div className="row row--5">
                            {wine_list.map((wine, i) => {

                                const params = {
                                    manufacturer_id: wine.manufacturer.id,
                                    wine_id: wine.id
                                } as IRouteParamsModel;
                                const wine_url = routeBuilder(RouteType.wine_details, params);

                                return (
                                    <div className="col-lg-4 col-md-6 col-12" key={i} style={{justifyContent: "center", alignItems: "center", overflow: "hidden", display: "flex"}}>
                                        <div className="portfolio-style--3">
                                            <div className="thumbnail">
                                                <a href={wine_url}>
                                                    <img
                                                        className="w-100"
                                                        src={wine.logo.url}
                                                        alt={wine.logo.name}
                                                    />
                                                </a>
                                            </div>
                                            <div className="content">
                                                <h4 className="title portfolio-btn">
                                                    <a href={wine_url}>{wine.name}</a>
                                                </h4>
                                                <div className="portfolio-btn">
                                                    <a className="rn-btn text-white" href={wine_url}>
                                                        Read More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <Pagination
                    total_items={total_count}
                    size={PAGE_SIZE}
                    current_page={current_page}
                    onPageChange={(page) => this.setState({current_page: page}, () => this.getWines())}
                    onNextPage={() => this.setState({current_page: current_page + 1}, () => this.getWines())}
                    onPreviousPage={() => this.setState({current_page: current_page - 1}, () => this.getWines())}
                />
            </React.Fragment>
        );
    }
}
