import * as React from "react";
import Slider from "react-slick";
import {SliderItem} from "./model";

interface Props {
    sliders: Array<SliderItem>;
}

const slideConfig = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    fade: true,
    easing: "fade",
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 4000
};

export default class SliderComponent extends React.Component<Props> {
        
    render() {
        const {sliders} = this.props;
        
        return (
            <div className="slider-wrapper">
                <div className="slider-activation">
                    <Slider className="rn-slick-dot dot-light" {...slideConfig}>
                        {sliders.map((value, index) => (
                            <div key={index}>
                                <div
                                    className="slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image"
                                    style={{
                                        backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/bg/${value.bgImage}")`
                                    }}
                                >
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? (
                                                        <span>{value.category}</span>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {value.title ? (
                                                        <h1 className="title">{value.title}</h1>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {value.description ? (
                                                        <p className="description">
                                                            {value.description}
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {value.buttonText ? (
                                                        <div className="slide-btn">
                                                            <a
                                                                className="rn-button-style--2 btn-solid"
                                                                href={`${value.buttonLink}`}
                                                            >
                                                                {value.buttonText}
                                                            </a>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        );
    }
}
