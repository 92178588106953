import React, {Component} from "react";
import {Helmet} from "react-helmet";

interface Props {
    title: string;
}

export default class PageHelmet extends Component<Props> {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Vinoroc - {this.props.title} </title>
                    <meta
                        name="description"
                        content="Vinoroc Inc. - US importer of Moldovan wines."
                    />
                </Helmet>
            </React.Fragment>
        );
    }
}
