
const isValidEmail = (email: string): boolean => {
    if(isStringEmpty(email)) return true;
    
    return /\S+@\S+\.\S+/.test(email);
}

const isStringEmpty = (str: string): boolean => {
    return str === null || str.trim() === "";
}

export {isValidEmail};