import * as React from "react";

import SliderComponent from "components/slider";
import {SliderItem} from "components/slider/model";
import LatestBlogs from "./sections/latestBlogs";
import TeaserWines from "./sections/teaserWines";
import Notification from "components/notification";
import {NotificationType} from "enums";
import Layout from "components/common/layout";
import {routeBuilder} from "utils";
import {RouteType} from "enums";

const sliders = [
    {
        textPosition: "text-left",
        bgImage: "slide_3_Fautor_Vineyard.jpg",
        category: "",
        title: "",
        description: "",
        buttonText: "",
        buttonLink: routeBuilder(RouteType.contact, {})
    },
    {
        textPosition: "text-left",
        bgImage: "slide_4_Fautour_Winery.jpeg",
        category: "",
        title: "",
        description: "",
        buttonText: "",
        buttonLink: routeBuilder(RouteType.contact, {})
    },
    {
        textPosition: "text-right",
        bgImage: "slider_1_moldova.jpg",
        category: "",
        title: "",
        description: "",
        buttonText: "",
        buttonLink: routeBuilder(RouteType.contact, {})
    }
];

interface State {
    sliders: Array<SliderItem>;
}

export default class HomePage extends React.Component<{}, State> {
    private notification: Notification;

    constructor(props) {
        super(props);
        this.state = {
            sliders: sliders
        };

        this.notification = new Notification();
    }

    render() {
        return (
            <Layout title="Home">
                {/* Start Slider Area   */}
                <SliderComponent sliders={this.state.sliders} />

                {/* Start Related News Area */}
                <TeaserWines />

                <div style={{paddingBottom: 5}}>
                    <img
                        src="/assets/images/bg/grapes.jpg"
                        alt="Vinoroc Grapes"
                        style={{width: "100%"}}
                    />
                </div>

                {/* Start Blog Area */}
                <LatestBlogs />
            </Layout>
        );
    }
}
