import * as api from "services/api";
import IRegionModel from "models/regionModel";
import IPaginatedModel from "models/paginatedModel";

const getAll = (page: number): Promise<IPaginatedModel<IRegionModel>> => {
    return new Promise<IPaginatedModel<IRegionModel>>((resolve, reject) => {
        resolve(
            api.http().get<IPaginatedModel<IRegionModel>>(
                api.createUrl(`regions?PageNumber=${page}&PageSize=50`)
            ).then((response) => {

                return {
                    result: response.data.result,
                    totalCount: response.data.totalCount
                } as IPaginatedModel<IRegionModel>;
            })
        )
    })
};

const RegionService = {
    getAll
};

export default RegionService;
